import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Navbar from "../components/Navbar";
import Image from "../components/image";
import SEO from "../components/seo";

const BlogPost = ({ node }) => {
  //console.log(node.featuredImage.fixed.src);
  //console.log(node.id);
  return (
      <li className="column is-4">
      <Link  to={node.slug}>
        <div className="card">
          <div className="card-image">
            <figure className="image">
              <img src={node.featuredImage.fluid.src} alt={node.title} />
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{node.title}</p>
              </div>
            </div>
            <div className="content">
              {node.content.childMarkdownRemark.excerpt}
            </div>
          </div>
        </div>
        </Link>
      </li>
    
  );
};

const Pagination = ({data,pageContext}) => {
  const posts = data.allContentfulBlogPost.edges;
  const featuredPost = posts[0].node;
  const { numPages, currentPage } = pageContext;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numPages;

  const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1;
  const nextPageNum = currentPage + 1;

  const prevPageUrl = `/${prevPageNum}/`;
  const nextPageUrl = `/${nextPageNum}/`;

  return (
    <nav
    className="pagination is-centered"
      role="navigation"
      aria-label="pagination"
    >
      {!isFirstPage && <Link to={prevPageUrl} className="pagination-previous">Previous</Link>}
      {!isLastPage && <Link to={nextPageUrl} className="pagination-next">Next</Link>}
      <ul className="pagination-list">
        <li key="1">
        <Link to={`/`} className="pagination-link">1</Link>
        </li>
        <li key="2">
        <Link to={`/2/`} className="pagination-link">2</Link>
        </li>
        {/* <li>
          <a
            class="pagination-link is-current"
            aria-label="Page 46"
            aria-current="page"
          >
            46
          </a>
        </li> */}
        {/* <li key="3">
        <Link to={`/3/`} className="pagination-link">3</Link>
        </li> */}
        {/* <li>
          <span class="pagination-ellipsis">&hellip;</span>
        </li> */}
        {/* <li>
        <Link to={`/4/`} className="pagination-link">4</Link>
        </li> */}
      </ul>
    </nav>
  );
};
const IndexPage = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges;
  const featuredPost = posts[0].node;
  const { numPages, currentPage } = pageContext;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numPages;

  const prevPageNum = currentPage - 1 === 1 ? `` : currentPage - 1;
  const nextPageNum = currentPage + 1;

  const prevPageUrl = `/${prevPageNum}/`;
  const nextPageUrl = `/${nextPageNum}/`;

  return (
    <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div>
      {/* <Navbar /> */}
      <div>
        <ul className="columns is-mobile is-multiline is-variable is-1-mobile is-0-tablet is-2-desktop is-8-widescreen is-2-fullhd">
          {posts.map(edge => (
            <BlogPost node={edge.node} key={edge.node.id}/>
          ))}
        </ul>
        <br/>
        <Pagination data={data} pageContext={pageContext}/>
        <br/>
      </div>
      {/* <div>
        {currentPage}/{numPages}
      </div>
      {!isFirstPage && <Link to={prevPageUrl}>Previous</Link>}
      {!isLastPage && <Link to={nextPageUrl}>Next</Link>} */}
    </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(limit: $limit, skip: $skip) {
      edges {
        node {
          title
          id
          slug
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          featuredImage {
            fluid(maxWidth: 1800) {
              src
            }
          }
        }
      }
    }
  }
`;
